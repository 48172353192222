import {IEntityAction, ActionMeta} from 'app/blocks/store/actions/entity-actions';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {MposDate} from 'app/blocks/util/mpos-date';
import {IServiceOrder} from 'app/blocks/model/service-order.model';
import {IMposfile} from 'app/blocks/model/mpos-file.model';
import {INote} from 'app/blocks/model/note.model';
export interface ICreateServiceOrder extends IEntityAction {
    entity?: IServiceOrder;
    uploadDocuments?: IMposfile[];
    deletedDocuments?: IMposfile[];
}

export interface IUpdateServiceOrder extends IEntityAction {
    entity?: IServiceOrder;
    uploadDocuments?: IMposfile[];
    deletedDocuments?: IMposfile[];
}

export class ReceiveInventory implements IEntityAction {
    static readonly type = '[Incoming Inventory] Receive Inventory';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public adjDate: MposDate,
        public adjTime: string,
        public meta?: ActionMeta
    ) {}
}

export class ConfirmSaleOrder implements IEntityAction {
    static readonly type = '[Sale Order] Confirm';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public meta?: ActionMeta
    ) {}
}

export class ConfirmServiceOrder implements IEntityAction {
    static readonly type = '[Service Order] Confirm';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public meta?: ActionMeta
    ) {}
}

export class CancelServiceOrder implements IEntityAction {
    static readonly type = '[Service Order] Cancel';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public meta?: ActionMeta
    ) {}
}

export class CancelSaleOrder implements IEntityAction {
    static readonly type = '[Sale Order] Cancel';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public date: any,
        public time: string,
        public meta?: ActionMeta
    ) {}
}

export class UncancelSaleOrder implements IEntityAction {
    static readonly type = '[Sale Order] UnCancel';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public date: any,
        public time: string,
        public meta?: ActionMeta
    ) {}
}

export class StartPickOrder implements IEntityAction {
    static readonly type = '[Pick Order] Start';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public request: any,
        public meta?: ActionMeta
    ) {}
}

export class MarkPickOrderAsPending implements IEntityAction {
    static readonly type = '[Pick Order] Mark As Pending';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public notes: INote,
        public markAsPendingDate: MposDate,
        public markAsPendingTime: string,
        public orderItems: any[],
        public meta?: ActionMeta
    ) {}
}

export class ValidatePickOrder implements IEntityAction {
    static readonly type = '[Pick Order] Validate';
    constructor(
        public resource: AbstractEntityResource<any>,
        public request: any,
        public meta?: ActionMeta
    ) {}
}

export class CompletePackOrder implements IEntityAction {
    static readonly type = '[Pack Order] Complete';
    constructor(
        public resource: AbstractEntityResource<any>,
        public request: any,
        public meta?: ActionMeta
    ) {}
}

export class StartPackOrder implements IEntityAction {
    static readonly type = '[Pack Order] Start';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public meta?: ActionMeta
    ) {}
}

export class ProcessLoadOrder implements IEntityAction {
    static readonly type = '[Load Order] Process';
    constructor(
        public resource: AbstractEntityResource<any>,
        public request: any,
        public meta?: ActionMeta
    ) {}
}

export class CancelLoadOrder implements IEntityAction {
    static readonly type = '[Load Order] Cancel';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public cancelDate: MposDate,
        public cancelTime: string,
        public meta?: ActionMeta
    ) {}
}

export class ConfirmPurchaseOrder implements IEntityAction {
    static readonly type = '[Purchase Order] Confirm';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public meta?: ActionMeta
    ) {}
}

export class CancelPurchaseOrder implements IEntityAction {
    static readonly type = '[Purchase Order] Cancel';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public date: any,
        public time: string,
        public meta?: ActionMeta
    ) {}
}

export class ReceivePurchaseOrder implements IEntityAction {
    static readonly type = '[Purchase Order] Receive';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public meta?: ActionMeta
    ) {}
}

export class CreateServiceOrder implements ICreateServiceOrder {
    static readonly type = '[Service Order] Create Service Order';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: IServiceOrder,
        public uploadDocuments?: IMposfile[],
        meta?: ActionMeta
    ) {}
}

export class UpdateServiceOrder implements IUpdateServiceOrder {
    static readonly type = '[Service Order] Update Service Order';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: IServiceOrder,
        public uploadDocuments?: IMposfile[],
        public deletedDocuments?: IMposfile[],
        meta?: ActionMeta
    ) {}
}
export class ServicedServiceOrder implements IEntityAction {
    static readonly type = '[Service Order] Serviced';
    constructor(
        public resource: AbstractEntityResource<any>,
        public id: number,
        public meta?: ActionMeta
    ) {}
}
